import React from 'react';
import { Helmet } from 'react-helmet';

// react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

// assets
import '../assets/scss/main.scss';
import favicon from '../assets/images/fi-rr-settings.svg';

// components
import Navigation from './navigation';

const Layout = ({children}) => {
  return (
    <div id="app">
      <Helmet htmlAttributes={{"lang": "pl"}}>
        <meta charSet="utf-8" />
        <title>tkd-it.pl</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <Navigation />
      <Container>
        {children}
      </Container>
      <footer>
        <small className="d-flex justify-content-end align-items-center mr-2 mb-1">All rights reserved &copy; 2021 tkd-it.pl</small>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9871.064836787047!2d19.454534!3d51.792159!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf941c09587a8ad95!2sTKD-IT!5e0!3m2!1spl!2spl!4v1620345835048!5m2!1spl!2spl" style={{border: 0}} allowfullscreen="" loading="lazy" className="map__frame" title="localization"></iframe>
      </footer>
    </div>
  );
}

export default Layout;